import { ArrowBack as ArrowBackIcon, FilterList } from '@mui/icons-material';
import {
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron_up.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Gallery } from 'assets/icons/Vector.svg';
import Loader from 'components/atoms/Loader';
import NoResult from 'components/atoms/NoResult';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useNavigate } from 'react-router-dom';
import { getWorkDetails, getWorkProgress } from 'redux/ConstructionDashboard';
import { useAppDispatch, useAppSelector } from 'redux/store';

import ConstructionFilter from './components/ConstructionFilter';

const CommonContainer = styled(Paper)`
  margin-top: 14px;
  padding: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-shadow: none;
  gap: 10px;
  border: 1px solid #e3e6e8;
`;

const CustomCard = ({ title, count, color }) => {
  return (
    <Card
      sx={{
        flex: 1,
        background: '#F5F6F9',
        boxShadow: 'none',
        '& .MuiCardContent-root:last-child': { paddingBottom: '18px' },
      }}
    >
      <CardContent
        sx={{
          p: '18px 20px 0px 20px',
        }}
      >
        <h3>{count || 0}</h3>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            mt: 1,
          }}
        >
          <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect fill={color} height="16" rx="8" width="16" />
          </svg>
          <Typography>{title}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export const MainCard = props => {
  const { workData, key, onImageClick, handleClick } = props;
  const {
    work_path,
    percentage,
    percentage_completed,
    executor,
    images,
    date_time,
    status,
    work_id,
  } = workData;

  return (
    <CommonContainer key={key}>
      <div className="d-flex justify-content-between">
        <Typography>{work_path}</Typography>
        <Typography
          color={status === 'completed' ? '#07CA03' : status === 'delayed' ? '#FF5D5D' : '#F4AF48'}
        >
          {status}
        </Typography>
      </div>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        <span style={{ color: '#4872F4' }}>{percentage} %</span>
        <span style={{ color: '#868686' }}>Added Progress</span>
        <svg fill="none" height="6" viewBox="0 0 6 6" width="6" xmlns="http://www.w3.org/2000/svg">
          <rect fill="#000000de" height="6" rx="8" width="6" />
        </svg>
        <span style={{ color: '#4872F4' }}>{percentage_completed} %</span>
        <span style={{ color: '#868686' }}>Total Progress</span>
      </div>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        <svg
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
            stroke="#000000de"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
            stroke="#000000de"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
        <span>{executor}</span>
      </div>
      <div>
        <span style={{ color: '#868686', fontWeight: 'bold' }}>{date_time}</span>
      </div>
      <div className="d-flex justify-content-between">
        {images?.length > 0 && (
          <div>
            <div
              style={styles.discriptonSvgWrapper}
              onClick={() => {
                onImageClick();
              }}
            >
              <Gallery />
              <Typography sx={{ fontSize: '1.1rem', color: '#4872F4' }}>
                {images?.length > 1 ? `1 + ` : '1'}
              </Typography>
              <Typography sx={{ fontSize: '1.1rem', color: '#4872F4' }}>View Images</Typography>
            </div>
          </div>
        )}

        <div style={{ marginLeft: 'auto' }}>
          <IconButton
            className="mr-3 mb-2"
            style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
            onClick={() => handleClick(work_id)}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </CommonContainer>
  );
};

const Construction = () => {
  const dispatch = useAppDispatch();
  // const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const [toggleOpen, setToggleOpen] = useState(false);

  const project_id = Cookies.get('_hjproject_idUser_2492391');
  const encProjectID = Cookies.get('_hjenc_project_idUser_2492391');
  // const project_id = '260';
  const { getWorkProgressData, getWorkDetailsData, construction_filter, loading } = useAppSelector(
    s => s.constructiondashboard,
  );
  const { tasks, delayed, task_completed, in_progress } = getWorkProgressData;
  const { executions_count, executions } = getWorkDetailsData;

  const imagesURL = executions?.flatMap(execution =>
    execution?.images.map(i => ({ url: i.image_url })),
  );

  const loadData = () => {
    const workDetailsPayload = {
      project_id,
      progress_status: construction_filter.progress_status,
      work_id: Number(construction_filter.work_id),
      label_id: Number(construction_filter.label_id),
      assignee: construction_filter.assignee,
      fromDate: construction_filter.fromDate,
      toDate: construction_filter.toDate,
    } as {
      project_id: string;
      progress_status: string;
      work_id: number;
      label_id: number;
      assignee: number[];
      fromDate: string;
      toDate: string;
      duration?: string;
    };

    if (construction_filter.duration !== '2') {
      workDetailsPayload.duration = construction_filter.duration;
    }
    const workProgressPayload = {
      project_id,
      fromDate: construction_filter.fromDate,
      toDate: construction_filter.toDate,
    } as {
      project_id: string;
      fromDate: string;
      toDate: string;
      duration?: string;
    };

    if (construction_filter.duration !== '2') {
      workProgressPayload.duration = construction_filter.duration;
    }
    dispatch(getWorkProgress(workProgressPayload));
    dispatch(getWorkDetails(workDetailsPayload));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [construction_filter]);

  const handleBack = () => {
    navigate(-1);
  };

  const onImageClick = () => {
    setIsOpen(true);
  };

  const onClickHandlerClose = () => {
    setIsOpen(false);
  };

  const toggleDrawer = () => {
    setToggleOpen(!toggleOpen);
  };
  const filterCount = useMemo(() => {
    return Object.values(construction_filter)?.filter(i => {
      const isExcluded =
        i === '0' ||
        i === '' ||
        i === 0 ||
        i === null ||
        (Array.isArray(i) ? i.length === 0 : false) ||
        (typeof i === 'string' && i === '2' && construction_filter.duration === '2');

      return !isExcluded;
    }).length;
  }, [construction_filter]);

  const handleExecutionNav = async work_id => {
    // old site navigation
    const OLD_SITE =
      await `${process.env.REACT_APP_REDIRECT_URL}wbsnew/${encProjectID}/wbs_work/${work_id}`;
    await window.location.replace(OLD_SITE);
  };
  const topRef = useRef(null);

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <>
      <Loader loading={loading} />
      <ConstructionFilter open={toggleOpen} toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          p: '14px 24px',
          mt: 1,
          height: `calc(100vh - 73px)`,
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Box ref={topRef} className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center my-3">
            <IconButton
              className="mr-3 mb-2"
              style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
              onClick={handleBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <h4 className="mb-0">
              Construction Dashboard <Chip className="mb-2" color="success" label="Beta" />
            </h4>
          </div>
          <div>
            <IconButton
              aria-label="filter"
              color="primary"
              sx={{ backgroundColor: 'rgb(229 234 250)' }}
              onClick={toggleDrawer}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                badgeContent={filterCount}
                color="primary"
              >
                <FilterList />
              </Badge>
            </IconButton>
          </div>
        </Box>
        <Box mt={5}>
          <h4>Tasks</h4>
          <CommonContainer>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              <CustomCard color={'#4872F4'} count={tasks} title="Updated" />
              <CustomCard color={'#22C55E'} count={task_completed} title="Completed" />
              <CustomCard color={'#EAB308'} count={in_progress} title="InProgress" />
              <CustomCard color={'#FF5D5D'} count={delayed} title="Delayed" />
            </div>
          </CommonContainer>
        </Box>
        <Box mt={3}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div className="d-flex align-items-center">
              <h6>Total Task Listed</h6>
              <Chip
                label={executions_count || 0}
                sx={{ background: '#5E6D7C', color: '#fff', ml: 2 }}
              />
            </div>
            <p className="ml-2 mb-0">Reports</p>
            <div className="row">
              <div className="col-12">
                {executions && executions?.length > 0 ? (
                  executions?.map((workData, workIndex) => {
                    return (
                      <MainCard
                        key={workIndex}
                        handleClick={handleExecutionNav}
                        workData={workData}
                        onImageClick={onImageClick}
                      />
                    );
                  })
                ) : (
                  <NoResult />
                )}
                {isOpen && (
                  <Lightbox
                    mainSrc={imagesURL[photoIndex]?.url}
                    nextSrc={imagesURL[(photoIndex + 1) % imagesURL?.length]?.url}
                    prevSrc={
                      imagesURL[(photoIndex + imagesURL.length - 1) % imagesURL?.length]?.url
                    }
                    onCloseRequest={onClickHandlerClose}
                    onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % imagesURL?.length)}
                    onMovePrevRequest={() =>
                      setphotoIndex((photoIndex + imagesURL?.length - 1) % imagesURL?.length)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
        <IconButton
          disableRipple
          style={{
            position: 'fixed',
            bottom: '40px',
            right: '60px',
            height: 52,
            width: 52,
            borderRadius: 8,
            background: '#4872F4',
            boxShadow: '0px 4px 8px 3px #00000026',
          }}
          onClick={scrollToTop}
        >
          <ChevronUp />
        </IconButton>
      </Box>
    </>
  );
};

const styles = {
  discriptonSvgWrapper: {
    display: 'flex',
    gap: '8px',
    marginBottom: '10px',
    border: '1px',
    borderStyle: 'solid',
    width: 'fit-content',
    padding: '5px',
    borderRadius: '4px',
    borderColor: '#e8e8e8',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default Construction;
