import { Cancel as CancelIcon } from '@mui/icons-material';
import { Button, Divider, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import {
  getAssigneeOption,
  getLevelOption,
  getWorkOption,
  updateConstructionFilters,
} from 'redux/ConstructionDashboard';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { DEFAULT_CONSTRUCTION_FILTERS } from 'utils/constant';
import * as Yup from 'yup';

const ApplyBtn = styled(Button)`
  background: rgba(72, 114, 244, 0.1);
  color: #4872f4;
  margin: 5px 10px 0;
  padding: 4px 15px;
  font-size: 16px;
  transition: background-color 0.3s;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  &:hover {
    color: #fff;
    background-color: #4872f4;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
`;

const ClearBtn = styled(Button)`
  background: rgba(255, 93, 93, 0.2);
  color: #ff5d5d;
  margin: 5px 0 0 10px;
  padding: 4px 15px;
  font-size: 16px;
  transition: background-color 0.5s;
  border-radius: 8px;
  box-shadow: none;
  text-transform: none;
  border: #ff5d5d;
  &:hover {
    background-color: #ff5d5d;
    color: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }
`;
const PROJECT_ID = Cookies.get('_hjproject_idUser_2492391');
// const PROJECT_ID = '260';
const ConstructionFilter = props => {
  const { toggleDrawer, open } = props;
  const { construction_filter } = useAppSelector(s => s.constructiondashboard);

  const dispatch = useAppDispatch();
  const STATUS_OPTIONS = [
    { label: 'In progress', value: 'in-progress' },
    { label: 'Delayed', value: 'delayed' },
    { label: 'Completed', value: 'completed' },
  ];

  const DURATION_OPTIONS = [
    { label: 'Daily', value: '0' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: '1' },
    { label: 'Custom Date', value: '2' },
  ];

  // redux state values
  const {
    AssigneeList: assigneeList,
    LevelList: levelList,
    WorkList: workList,
  } = useAppSelector(s => s.constructiondashboard);

  useEffect(() => {
    dispatch(getAssigneeOption({ project_id: PROJECT_ID }));
    dispatch(getLevelOption({ project_id: PROJECT_ID }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async values => {
    await dispatch(updateConstructionFilters(values));
    toggleDrawer();
  };
  const clearForm = async () => {
    await dispatch(updateConstructionFilters(DEFAULT_CONSTRUCTION_FILTERS));

    await dispatch(
      getWorkOption({
        project_id: PROJECT_ID,
        level_id: 0,
      }),
    );
    formikProps.resetForm();
    toggleDrawer();
  };

  const validationSchema = Yup.object().shape({
    duration: Yup.string().required('Duration is required'),
    fromDate: Yup.string()
      .when('duration', {
        is: (value: string) => value == '2',
        then: schema => schema.required('From is required'),
      })
      .nullable(),
    toDate: Yup.string()
      .when('duration', {
        is: (value: string) => value == '2',
        then: schema => schema.required('To is required'),
      })
      .nullable(),
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: construction_filter,
    validationSchema,
    onSubmit,
  });

  const { setFieldValue, values, handleChange, errors, validateForm } = formikProps;
  const handleSubmit = async () => {
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length === 0) {
      await onSubmit(values);
    } else {
      console.log(validationErrors);
    }
  };

  // Assignee Options
  const assigneeOptions = useMemo(() => {
    const assigneeLists = assigneeList?.map(e => ({
      label: `${e.assignee_name}`,
      value: e.id,
    }));
    return [...assigneeLists];
  }, [assigneeList]);

  // Levels Options
  const levelsListOptions = useMemo(() => {
    const levelOptions = levelList
      ?.filter(v => v.title !== '')
      ?.map(e => ({
        label: `${e.title}`,
        value: e.id,
      }));
    return [...levelOptions];
  }, [levelList]);

  // Work Options
  const workListOptions = useMemo(() => {
    const workListOptionss = workList?.map(e => ({
      label: `${e.work_title}`,
      value: e.work_id,
    }));
    return [...workListOptionss];
  }, [workList]);

  const handleLevelChange = async e => {
    const LevelId = e.value;
    formikProps.setFieldValue('label_id', LevelId);
    formikProps.setFieldValue('work_id', null);

    await dispatch(
      getWorkOption({
        project_id: PROJECT_ID,
        level_id: LevelId,
      }),
    );
  };
  const handleChangeAssignee = (key, value) => {
    const id = value.map(v => v.value);
    setFieldValue(key, id);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '12px',
        },
      }}
      onClose={toggleDrawer}
    >
      <div
        style={{
          height: '48px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px 15px',
          marginBottom: '10px',
        }}
      >
        <p>Construction Filters</p>
        <CancelIcon
          sx={{
            backgroundColor: '#FFE1E1',
            borderRadius: '80px',
            height: '26.67px',
            width: '26.67px',
            color: '#FF5D5D',
            cursor: 'pointer',
          }}
          onClick={toggleDrawer}
        />
      </div>
      <Divider sx={{ mt: 2 }} />
      <div style={{ width: '388px' }}>
        <div style={{ padding: '0 15px', gap: '16px' }}>
          <div className="d-flex justify-content-end">
            <ApplyBtn onClick={handleSubmit}>Apply</ApplyBtn>
            <ClearBtn onClick={clearForm}>Clear</ClearBtn>
          </div>
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Duration
            </Typography>
            <Select
              closeMenuOnSelect={true}
              defaultValue={DURATION_OPTIONS.find(v => v.value === values.duration)}
              name="duration"
              options={DURATION_OPTIONS}
              placeholder="Select Duration"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={v => setFieldValue('duration', v.value)}
            />
          </div>
          {values.duration === '2' && (
            <>
              <div className="my-3">
                <Typography className="muted-text" variant="caption">
                  From
                </Typography>
                <input
                  className="form-control"
                  name="fromDate"
                  type="date"
                  value={values.fromDate}
                  onChange={handleChange}
                />
                {errors.fromDate && typeof errors.fromDate === 'string' && (
                  <p style={{ color: 'red', marginBottom: 10,marginTop:10 }}>{errors.fromDate}</p>
                )}
              </div>
              <div className="my-3">
                <Typography className="muted-text" variant="caption">
                  To
                </Typography>
                <input
                  className="form-control"
                  name="toDate"
                  type="date"
                  value={values.toDate}
                  onChange={handleChange}
                />

                {errors.toDate && typeof errors.toDate === 'string' && (
                  <p style={{ color: 'red',  marginBottom: 10,marginTop:10 }}>{errors.toDate}</p>
                )}
              </div>
            </>
          )}
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Status
            </Typography>
            <Select
              closeMenuOnSelect={true}
              defaultValue={STATUS_OPTIONS.find(v => v.value === values.progress_status)}
              name="status"
              options={STATUS_OPTIONS}
              placeholder="Select Status"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={v => setFieldValue('progress_status', v.value)}
            />
          </div>
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Assignee
            </Typography>
            <Select
              isMulti
              closeMenuOnSelect={false}
              defaultValue={assigneeOptions?.filter(option =>
                values.assignee?.includes(option.value),
              )}
              name="assignee"
              options={assigneeOptions}
              placeholder="Select Assignee"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={v => handleChangeAssignee('assignee', v)}
            />
          </div>
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Level
            </Typography>
            <Select
              closeMenuOnSelect={true}
              defaultValue={levelsListOptions.find(v => v.value === values.label_id)}
              name="label"
              options={levelsListOptions}
              placeholder="Select Level"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={handleLevelChange}
            />
          </div>
          <div className="my-3">
            <Typography className="muted-text" variant="caption">
              Work
            </Typography>
            <Select
              closeMenuOnSelect={true}
              defaultValue={workListOptions.find(v => v.value === values.work_id)}
              name="work"
              options={workListOptions}
              placeholder="Select Work"
              styles={{
                container: base => ({
                  ...base,
                }),
              }}
              onChange={v => setFieldValue('work_id', v.value)}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ConstructionFilter;
